import { createGlobalStyle } from "styled-components"
import "typeface-pt-serif"
import "typeface-lato"

const GlobalStyles = createGlobalStyle`
  :root {
    --dark-green: #1d3026;
    --light-green: #99A697;
    --headline: #1C3C2C;
    --font: #2e2f2d;
  }


/*------------------------------------*\
    BASICS
\*------------------------------------*/

 
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    color: var(--font);
    font-family: "Lato";
    font-weight: 300;
    font-size: 1.9rem;
    line-height: 1.618;
    background-color: var(--body);
}

main {
  max-width: 100vw;
}

body.no-scroll {
    height: 100vh;
    overflow: hidden;
}

img {
    max-width: 100%;
}


a {
    color: inherit;
    text-decoration: none;
    transition: all .3s ease-in;
    outline: none;
}



h1,
h2
 {
  font-family: 'PT Serif', serif;
    font-weight: 400;
    font-size: 4rem;
    color: var(--headline);
    line-height: 1;
    padding: 2rem 0;
}

h3 {
    font-family: 'PT Serif', serif;
    font-style: italic;
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--light-green);
    line-height: 1.2;
    padding: 2rem 0;
}



input {
    margin-right: 10px
}

audio {
    outline: none;
}

/*------------------------------------*\
    HELPER
\*------------------------------------*/



.v-img {
    max-width: 480px;
    width: 100%;
    max-height: 100%;
    height: 550px;
    object-fit: cover;
}

.content-visibility {
  content-visibility: auto;
  contain-intrinsic-size: 60vh;
}

.active {
  color: var(--light-green)
}

/*------------------------------------*\
    TRANSITIONS
\*------------------------------------*/

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}


/*------------------------------------*\
    HAMBURGER
\*------------------------------------*/
.hamburger {
    z-index: 25;
    padding: 15px 0 ;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }
/*
   * Collapse
   */
  .hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

`

export default GlobalStyles
