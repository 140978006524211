import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import GlobalStyles from "./styles/globalStyles"
import "./styles/klaus-gridski.css"
import { Wrapper } from "./styles/container"

import Logo from "../images/logo.svg"
import Mobile from "../images/mobilelogo.svg"
import cn2 from "../images/cn2.svg"

/******************************************************************************************* STYLED
 *************************************************************************************************/

// header

const HeaderStyle = styled.header`
  background-color: white;
  max-width: 100vw;
  padding: 1.5rem 0;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    padding: 3rem 0;
  }
  z-index: 15;
`

// logo
const MobileLogo = styled.img`
  width: 200px;
  z-index: 15;
  position: relative;
`

// navigation
const Navigation = styled.ul`
  list-style: none;
  font-weight: 700;
  font-size: 1.6rem;
  li {
    margin-left: 4rem;
    transition: 0.1s ease;
    cursor: pointer;
    :hover {
      color: var(--light-green);
    }
  }
`

// mobile navigation
const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: white;
  min-height: 100vh; /* Fallback */
  min-height: -webkit-fill-available;
  padding: 7rem 4rem;
  z-index: 15;
  ul {
    font-weight: 700;
    list-style: none;
    li {
      opacity: 1;
      padding: 1rem 0;
      font-size: 2rem;
      /* animation-name: fadeIn;
      animation-fill-mode: forwards;
      animation-duration: 0.4s; */
    }
    /* li:first-child {
      animation-delay: 0.2s;
    }
    li:nth-child(2) {
      animation-delay: 0.3s;
    }
    li:nth-child(3) {
      animation-delay: 0.4s;
    }
    li:nth-child(4) {
      animation-delay: 0.5s;
    }
    li:nth-child(5) {
      animation-delay: 0.6s;
    }
    li:last-child {
      animation-delay: 0.7s;
    } */
  }
`

const MobileSocials = styled.div`
  margin-top: 3rem;
  a {
    opacity: 1;
    /* animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-delay: 0.8s; */
  }
  /* a:first-child {
    animation-delay: 0.9s;
  }
  a:nth-child(2) {
    animation-delay: 1s;
  } */
`

const SocialLinks = styled.a`
  display: flex;
  align-items: center;
  font-style: italic;
  font-family: "PT Serif";
  color: var(--light-green);
  margin-top: 1.5rem;

  ::before {
    display: inline-block;
    content: "";
    border-top: 1px solid #a7b2a5;
    width: 3rem;
    margin: 0 1rem;
  }
`

// footer
const Footer = styled.footer`
  background: var(--dark-green);
  color: white;
  font-weight: 500;
  padding: 3rem 0;
  font-size: 1.6rem;
  max-width: 100vw;
`

const MarginNeeded = styled(Link)`
  margin-right: 3rem;
`

const ConnectLogo = styled.img`
  height: 50px;
  width: 50px;
  animation: 25s linear 0s infinite normal none running rotation;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

/******************************************************************************************** LAYOUT COMPONENT
 **************************************************************************************************/

const Layout = ({ children }) => {
  // -----------------------------initialize state for mobile menu
  const [showMenu, setshowMenu] = useState(false)

  // ----------------------------function to change the state
  const menuActive = () => {
    setshowMenu(!showMenu)
    const body = document.querySelector("body")
    body.classList.toggle("no-scroll")
  }

  /***********  COMPONENT */

  return (
    <div className="page-container ">
      <GlobalStyles />
      <HeaderStyle>
        {/* // ---------------------------------------------------------------------------MOBILE NAVIGATION*/}

        <div className="bg-disappear">
          <Wrapper className="flex v-center between ">
            <Link title="Zur Startseite" to="/">
              <MobileLogo alt="Mobiles Logo" src={Mobile} />
            </Link>
            <button
              onClick={menuActive}
              className={`hamburger hamburger--collapse ${
                showMenu ? "is-active" : null
              }`}
              type="button"
              aria-label="Menü öffnen"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            {showMenu && (
              <MobileMenu
                className="flex vertical h-center v-center"
                onClick={menuActive}
              >
                <ul className="flex vertical between v-center h-center">
                  <li>
                    <Link title="zur Startseite" to="/">
                      Startseite
                    </Link>
                  </li>
                  <li>
                    <Link title="zur Coaching Seite" to="/coaching">
                      Coaching
                    </Link>
                  </li>
                  <li>
                    <Link title="zur Mediations Seite" to="/mediation">
                      Mediation
                    </Link>
                  </li>
                  <li>
                    <Link title="zur Trainigs Seite" to="/training">
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link title="zur Über Mich Seite" to="/about">
                      Über mich
                    </Link>
                  </li>
                  <li>
                    <Link title="zur Kontakt Seite" to="/contact">
                      Kontakt
                    </Link>
                  </li>
                </ul>
                <MobileSocials>
                  <SocialLinks
                    title="Christina Pabst anrufen"
                    href="tel:01726292865"
                  >
                    anrufen
                  </SocialLinks>
                  <SocialLinks
                    target="_blank"
                    title="Mail an Christina Pabst"
                    href="mailto:hallo@christinapabst.de"
                  >
                    mail schreiben
                  </SocialLinks>
                </MobileSocials>
              </MobileMenu>
            )}
          </Wrapper>
        </div>

        {/* // ---------------------------------------------------------------------------DESKTOP NAVIGATION*/}

        <Wrapper className="disappear bg-flex-appear between v-center ">
          <Link title="Zur Startseite" to="/">
            <img
              alt="Christine Pabst Logo"
              width="231px"
              height="40px"
              src={Logo}
            />
          </Link>
          <Navigation className="flex between">
            <li>
              <Link title="zur Startseite" to="/" activeClassName="active">
                Startseite
              </Link>
            </li>
            <li>
              <Link
                title="zur Coaching Seite"
                to="/coaching"
                activeClassName="active"
              >
                Coaching
              </Link>
            </li>
            <li>
              <Link
                title="zur Mediations Seite"
                to="/mediation"
                activeClassName="active"
              >
                Mediation
              </Link>
            </li>
            <li>
              <Link
                title="zur Trainigs Seite"
                to="/training"
                activeClassName="active"
              >
                Training
              </Link>
            </li>
            <li>
              <Link
                title="zur Über Mich Seite"
                to="/about"
                activeClassName="active"
              >
                Über mich
              </Link>
            </li>
            <li>
              <Link
                title="zur Kontakt Seite"
                to="/contact"
                activeClassName="active"
              >
                Kontakt
              </Link>
            </li>
          </Navigation>
        </Wrapper>
      </HeaderStyle>
      {/* // ---------------------------------------------------------------------------MAIN CONTENT*/}
      <main className="fade-in">{children}</main>
      {/* // ---------------------------------------------------------------------------FOOTER */}
      <Footer className="content-visibility">
        <Wrapper className="flex between v-center">
          <div>
            <MarginNeeded title="Zum Impressum" to="/impressum">
              Impressum
            </MarginNeeded>
            <Link title="Zur Datenschutzerklärung" to="/datenschutz">
              Datenschutz
            </Link>
          </div>
          <div>
            <a
              title="connect2 GmbH"
              target="_blank"
              rel="noopener noreferrer"
              href="https://connect2.gmbh/"
            >
              <ConnectLogo src={cn2} />
            </a>
          </div>
        </Wrapper>
      </Footer>
    </div>
  )
}

export default Layout
