import styled from "styled-components"

export const Section = styled.section`
  margin: 5rem 0;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    margin: 10rem 0 7rem 0;
  }
`

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  /* Width in PX > 1200px */
  @media only screen and (min-width: 75em) {
    width: 80%;
    max-width: 1700px;
    margin: 0 auto;
  }
`

export const Paragraph = styled.p`
  max-width: 750px;
  margin: 1rem 0;
`
